import styled, { css } from 'styled-components';
import { above } from '../../styles/libs';
import StyledText from '../ui/Text';
import Button from '../ui/Button';
import zIndex from '../../config/zIndex';

const borderWidth = '0.375rem';

const directions = {
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  'right-start': 'right',
  top: 'top',
};

function flipDirection(direction) {
  const flippedDirections = {
    bottom: 'top',
    left: 'right',
    right: 'left',
    'right-start': 'left',
    top: 'bottom',
  };

  return flippedDirections[direction];
}

function getVariant(theme, variant = 'dark') {
  const variants = {
    dark: {
      backgroundColor: theme.COLOR_GRANITE_GRAY,
      color: theme.COLOR_WHITE,
    },
    light: {
      backgroundColor: theme.COLOR_WARM_BACKGROUND,
      color: theme.COLOR_TEXT_BODY,
    },
  };

  return variants[variant];
}

function getSize(size = 'medium') {
  const sizes = {
    medium: {
      offset: {
        bottom: 12,
        left: 16,
        right: 16,
        top: 12,
      },
    },
    large: {
      offset: {
        bottom: 18,
        left: 24,
        right: 24,
        top: 18,
      },
    },
  };

  return sizes[size];
}

export const TooltipPositioner = styled.div`
  z-index: ${zIndex.POPOVER};
`;

export const PopOverArrow = styled.div`
  position: absolute;
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  ${({ placement, variant, theme }) => `border-${directions[placement]}-color: ${getVariant(theme, variant).backgroundColor};`}
  ${({ placement, size }) => `margin-${flipDirection(placement)}: -${(getSize(size).offset[directions[placement]] / 4) * 6}px;`}
  pointer-events: none;
  border-width: ${borderWidth};
`;

export const Wrapper = styled.div`
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  position: relative;
  width: 100%;
  z-index: ${zIndex.POPOVER};

  ${({ placement, size }) => `margin-${flipDirection(placement)}: ${getSize(size).offset[directions[placement]]}px;`}

  ${({ theme }) => above(theme.DESKTOP)`
    max-width: 292px;

    ${({ size }) => size === 'large' && css`
      max-width: 520px;
    `}
  `}

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) and (max-width: ${({ theme }) => theme.DESKTOP_LARGE}px) {
    ${({ welcomePopover }) => welcomePopover && css`
      // Screen width - search sidebar - basket - 24px margin around map
      max-width: calc(100vw - 600px - 3rem - 108px);
    `}
  }

  ${({ variant, theme }) => variant && css`
    background-color: ${getVariant(theme, variant).backgroundColor};
    color: ${getVariant(theme, variant).color};
  `}

  ${({ size }) => size === 'large' && css`
    padding: 2rem;
  `}
`;

export const MainTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const MainText = styled(StyledText)`
  margin-left: 0.5rem;
`;
