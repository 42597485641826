import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

import { withTheme } from 'styled-components';
import PopOverContent from './PopOverContent';

import {
  Wrapper,
  PopOverArrow,
} from './PopOver.style';

import { isTabletOrSmaller } from '../../lib/helpers/layout';

function PopOver({
  children,
  onClose,
  open,
  placement,
  renderContent,
  renderReference,
  size,
  variant,
}) {
  const customPlacement = placement || (isTabletOrSmaller() ? 'bottom' : 'right');

  const modifiers = {
    flip: { enabled: false },
    preventOverflow: { enabled: false },
    hide: { enabled: false },
  };

  const render = renderReference || children;

  return (
    <Manager>
      <>
        {render && (
          <Reference>
            {({ ref }) => render({ innerRef: ref })}
          </Reference>
        )}

        {open && (
          <Popper modifiers={modifiers} placement={customPlacement}>
            {({ ref, arrowProps, ...contentProps }) => (
              <PopOverContent
                onClose={onClose}
              >
                {isTabletOrSmaller() ? (
                  <Wrapper
                    size={size}
                    variant={variant}
                  >

                    <PopOverArrow
                      {...arrowProps}
                      placement={customPlacement}
                      size={size}
                      variant={variant}
                    />

                    {renderContent({ ...contentProps })}

                  </Wrapper>
                ) : (
                  <>
                    {createPortal(
                      <Wrapper
                        ref={ref}
                        size={size}
                        variant={variant}
                        {...contentProps}
                        style={{ ...contentProps.style, opacity: 1 }}
                      >
                        <PopOverArrow
                          {...arrowProps}
                          placement={customPlacement}
                          size={size}
                          variant={variant}
                        />
                        {renderContent({ ...contentProps })}
                      </Wrapper>,
                      window.document.getElementById('__next'),
                    )}
                  </>
                )}
              </PopOverContent>
            )}
          </Popper>
        )}
      </>
    </Manager>
  );
}

PopOver.propTypes = {
  children: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.string,
  renderContent: PropTypes.func.isRequired,
  renderReference: PropTypes.func,
  theme: PropTypes.shape({
    COLOR_POPOVER_DESKTOP: PropTypes.string,
  }),
  size: PropTypes.string,
  variant: PropTypes.string,
};

PopOver.defaultProps = {
  children: null,
  onClose() {},
  placement: '',
  renderReference: null,
  size: 'medium',
  theme: {
    COLOR_POPOVER_DESKTOP: '',
  },
  variant: 'dark',
};

export default withTheme(PopOver);
