import { useEffect } from 'react';
import PropTypes from 'prop-types';

function PopOverContent({
  children, onClose,
}) {
  useEffect(() => {
    window.document.addEventListener('mousedown', onClose);
    window.document.addEventListener('touchstart', onClose);

    return () => {
      window.document.removeEventListener('mousedown', onClose);
      window.document.removeEventListener('touchstart', onClose);
    };
  }, [onClose]);

  return children;
}

PopOverContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func.isRequired,
};

PopOverContent.defaultProps = {
  children: null,
};

export default PopOverContent;
